import React from "react";

const Story = () => {
  return (
    <>
      <div id="story" className="bg-white">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:py-6 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-black to-white sm:text-5xl sm:tracking-tight lg:text-6xl">
              Why choose us?
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              We take you through the process step by step, showing you how we
              work, and why we are the best choice for you!
            </p>
          </div>
        </div>
      </div>
      {/* Testimonial/stats section left */}
      <div className="relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-6">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="gray"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                ></rect>
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 md:h-96 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover object-top"
                  src="https://imgur.com/RI4QfHV.png"
                  alt=""
                />
                <div
                  className="absolute inset-0 bg-gray-500"
                  style={{ mixBlendMode: "multiply" }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-t from-gray-600 via-gray-600 opacity-20"></div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2
                id="sketching"
                className="text-3xl text-transparent bg-clip-text bg-gradient-to-br from-gray-400 to-black font-extrabold tracking-tight sm:text-4xl"
              >
                Finest modeling while maintaining precise proportions
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-lg">
                  Sketching is the ultimate preliminary step of jewellery
                  modeling and is charged with visual and verbal communication.
                  Every piece of jewellery starts out as a simple idea, then a
                  simple design, which results in a gorgeous piece of jewellery.
                  Our designers express their professional dedication and
                  creativity on a piece of paper first, then turn it into a work
                  of art. We offer this service to you, in order to create a
                  single piece of jewellery, or an entirely new line! In order
                  to start a custom jewellery design, feel free to write us or
                  give us a call and our specialists will guide you with
                  step-by-step dedicated assistance.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Precision
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-gray-400 to-black">
                    99.99%
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">Tests</dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-gray-400 to-black">
                    100+
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a href="/" className="text-base font-medium text-black">
                  Learn more about sketching&nbsp;→
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial/stats section right */}
      <div className="relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2
                id="designing"
                className="text-3xl text-transparent bg-clip-text bg-gradient-to-br from-green-600 to-blue-600 font-extrabold tracking-tight sm:text-4xl"
              >
                Best designing services, with precision and perfect ratios
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-lg">
                  The jewellery design process starts with initial jewellery
                  sketches. There is something beautiful about putting pencils
                  on paper, and our designers pay attention to every detail. Our
                  customers are then presented with initial drawings for review
                  and approval. This powerful process then brings us closer to
                  the creation process, which next includes 3D modeling.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Accuracy
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-green-600">
                    100%
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Weighting
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-green-600">
                    99.88%
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a href="/" className="text-base font-medium text-blue-500">
                  Learn more about designing&nbsp;→
                </a>
              </div>
            </div>
          </div>

          <div className="relative sm:py-16 lg:py-6">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 left-1/2 w-full bg-blue-50 rounded-l-3xl lg:left-72"></div>
              <svg
                className="absolute top-8 right-1/2 -ml-3 lg:-left-8 lg:right-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-blue-200"
                      fill="blue"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                ></rect>
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 md:h-96 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover object-top"
                  src="https://imgur.com/wMshI9H.png"
                  alt=""
                />
                <div
                  className="absolute inset-0 bg-blue-500"
                  style={{ mixBlendMode: "multiply" }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-20"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial/stats section left */}
      <div className="relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-6">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-green-50 rounded-r-3xl lg:right-72"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-green-200"
                      fill="green"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                ></rect>
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 md:h-96 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover object-top"
                  src="https://imgur.com/EGR1MaW.png"
                  alt=""
                />
                <div
                  className="absolute inset-0 bg-green-500"
                  style={{ mixBlendMode: "multiply" }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-t from-green-600 via-green-600 opacity-20"></div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2
                id="rapid-prototyping"
                className="text-3xl text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-green-900 font-extrabold tracking-tight sm:text-4xl"
              >
                Best results
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-lg">
                  3D jewellery modeling, known as CAD or Computer-aided Design,
                  is currently booming thanks to the many benefits it provides
                  to the jewelry creation process, namely time and cost.
                </p>
                <p className="text-base leading-7">
                  Our brilliant team offers clients professional services for
                  creating 3D jewellery models, making it possible to print and
                  thus create charming jewellery. Our 3D techniques of jewellery
                  modeling are our key success factors for clients and
                  designers.
                </p>
                <p className="text-base leading-7">
                  Our team provides clients with comprehensive A to Z services,
                  from sketching to the creation of the final product. Creating
                  a modern 3D jewellery model is the first step towards
                  transforming an idea into reality. Our talented jewellery
                  designers will bring your concept to life.
                </p>
                <p className="text-base leading-7">
                  Our team provides 3D jewellery services with guaranteed
                  precision and professionalism.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Results
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-green-900">
                    100%
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Service
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-green-900">
                    BEST
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a href="/" className="text-base font-medium text-green-500">
                  Learn more about rapid prototyping&nbsp;→
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial/stats section right */}
      <div className="relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2
                id="rendering"
                className="text-3xl text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-gray-400 font-extrabold tracking-tight sm:text-4xl"
              >
                Highest quality renderings
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-lg">
                  <p className="text-base leading-7">
                    Rapid jewellery prototyping with the creation of “renders”
                    is an effective way to show the client the jewellery design
                    before the creation process is underway. The 3D jewellery
                    modeling process is a real benefit for clients who cannot
                    find their jewellery model in jewellery stores and catalogs.
                    With our services, clients can see the final 3D models of
                    their jewelry designs before creating the pieces, which
                    allows them to fine-tune aspects such as symmetry and
                    proportions, and even helps clients select the gemstones to
                    be used.
                  </p>
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Quality
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-gray-400">
                    ~1080x1024
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    Frames per second
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-gray-400">
                    30+
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a href="/" className="text-base font-medium text-yellow-500">
                  Learn more about rendering&nbsp;→
                </a>
              </div>
            </div>
          </div>

          <div className="relative sm:py-16 lg:py-6">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 left-1/2 w-full bg-yellow-50 rounded-l-3xl lg:left-72"></div>
              <svg
                className="absolute top-8 right-1/2 -ml-3 lg:-left-8 lg:right-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-orange-200"
                      fill="orange"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                ></rect>
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 md:h-96 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover object-top"
                  src="https://imgur.com/lRrhQup.png"
                  alt=""
                />
                <div
                  className="absolute inset-0 bg-yellow-500"
                  style={{ mixBlendMode: "multiply" }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-t from-yellow-600 via-yellow-600 opacity-20"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Story;
