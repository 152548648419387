import React from "react";

const CTA = () => {
  return (
    <div id="contact-us" className="relative mt-24 sm:mt-32 sm:py-16">
      <div aria-hidden="true" className="hidden sm:block">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
        <svg
          className="absolute top-8 left-1/2 -ml-3"
          width="404"
          height="392"
          fill="none"
          viewBox="0 0 404 392"
        >
          <defs>
            <pattern
              id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="392"
            fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
          ></rect>
        </svg>
      </div>
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="relative rounded-2xl px-6 py-10 bg-yellow-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
          <div
            aria-hidden="true"
            className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              className="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                className="text-yellow-400 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              ></path>
              <path
                className="text-yellow-600 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              ></path>
            </svg>
          </div>
          <div className="relative">
            <div className="sm:text-center">
              <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                Contact us!
              </h2>
              <p className="mt-6 mx-auto max-w-2xl text-lg text-yellow-100">
                Email us at 👉 <a href="mailto:support@jsdesigners3d.com" className="no-underline text-yellow-800 font-bold">support@jsdesigners3d.com</a>
              </p>
              <p className="mt-6 mx-auto max-w-2xl text-lg text-yellow-100">
                Contact via whatsapp on 👉 <a href="https://api.whatsapp.com/send?phone=923452106402&text=💍%20Hey%20Jewellery%20Superior%20Designers%2C%20I%20want%20more%20info" target="_blank" rel="noopener noreferrer" className="no-underline text-yellow-800 font-bold">+92 345 2106402{" "}</a>
                <span className="font-extrabold text-sm">OR</span> 
                <a href="https://api.whatsapp.com/send?phone=923452522265&text=💍%20Hey%20Jewellery%20Superior%20Designers%2C%20I%20want%20more%20info" target="_blank" rel="noopener noreferrer" className="no-underline text-yellow-800 font-bold">{" "}+92 345 2522265</a>
              </p>
            </div>
            <form action="#" className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
              <div className="min-w-0 flex-1">
                <label htmlFor="cta_email" className="sr-only">
                  Email address
                </label>
                <input
                  id="cta_email"
                  type="email"
                  className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md px-5 py-3 bg-gradient-to-r from-yellow-400 to-yellow-600 text-base font-medium text-white shadow-md hover:bg-yellow-600 sm:px-10"
                >
                  Contact Us
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
