import React from "react";
import VanillaTilt from "vanilla-tilt";
import CrossfadeImage from "react-crossfade-image";

const Gallery = () => {
  const [index, setIndex] = React.useState(0);

  const images = [
    "https://imgur.com/esOhv0T.png",
    "https://imgur.com/3mXXRb5.png",
    "https://imgur.com/1R6pRZu.png",
  ];

  const colors = ["blue", "red", "green"];

  const rings = ["Sapphire", "Ruby", "Emerald"];

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      if (index === images.length - 1) {
        setIndex(0);
      } else {
        setIndex((index) => index + 1);
      }
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [index]);

  React.useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".js-gallery-image"), {
      max: 18,
      scale: 1.05,
      reset: true,
      startX: 10,
      transition: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      gyroscope: true,
      speed: 2000,
      glare: true,
    });
  }, []);

  return (
    <>
      <div id="gallery" className="bg-white mt-10">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:py-6 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-black to-white sm:text-5xl sm:tracking-tight lg:text-6xl">
              Examples of our work
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Learn every process step by step – see how we work and why we are the best choice for you!
            </p>
          </div>
        </div>
      </div>
      <div className="p-10 sm:px-32">
        <div className="grid grid-cols-1">
          <div className={`js-gallery-image shadow-lg m-24 cursor-pointer w-60 h-60 sm:w-96 sm:h-96 mx-auto bg-${colors[index]}-200 transition duration-1000`}>
            <div className="js-gallery-image-item w-72 h-72 sm:w-96 sm:h-96 relative bottom-10 drop-shadow-md">
              <CrossfadeImage
                src={images[index]}
                duration={1000}
                timingFunction={"ease-in"}
                alt=""
              />
            </div>

            <div className="js-gallery-image-text absolute -left-1 bottom-10 sm:-left-4">
              <div className={`text-3xl tracking-tight sm:text-5xl font-extrabold text-${colors[index]}-400 transition duration-1000`}>
                <span className="transition duration-75">{rings[index]}</span> Ring
              </div>
              <div className={`text-sm sm:text-base font-extrabold text-${colors[index]}-400 transition duration-1000`}>
                Premium gem stones setting
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-2 grid-cols-1">
          <div className="js-gallery-image shadow-lg m-24 cursor-pointer w-60 h-60 sm:w-96 sm:h-96 mx-auto bg-gradient-to-tr from-white via-yellow-200 to-yellow-400">
            <img
              className="js-gallery-image-item w-60 h-60 sm:w-96 sm:h-96 relative bottom-10 drop-shadow-md"
              src="https://imgur.com/lRrhQup.png"
              alt="galleryimage"
            />
            <div className="js-gallery-image-text absolute -left-1 bottom-10 sm:-left-4">
              <div className="text-3xl tracking-tight sm:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-yellow-600 to-yellow-200">
                Diamond Ring
              </div>
              <div className="text-sm sm:text-base font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-yellow-600 to-yellow-200">
                Awesome ring with fine gravings
              </div>
            </div>
          </div>

          <div className="js-gallery-image shadow-lg m-24 cursor-pointer w-60 h-60 sm:w-96 sm:h-96 mx-auto bg-gradient-to-tr from-white via-green-200 to-green-400">
            <img
              className="js-gallery-image-item w-60 h-60 sm:w-96 sm:h-96 relative bottom-10 drop-shadow-md"
              src="https://imgur.com/orrFZFG.png"
              alt="galleryimage"
            />
            <div className="js-gallery-image-text absolute -left-1 bottom-10 sm:-left-4">
              <div className="text-3xl tracking-tight sm:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-green-600 to-green-200">
                Channel Ring
              </div>
              <div className="text-sm sm:text-base font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-green-600 to-green-200">
                Ring with deep stones placements
              </div>
            </div>
          </div>

          <div className="js-gallery-image shadow-lg m-24 cursor-pointer w-60 h-60 sm:w-96 sm:h-96 mx-auto bg-gradient-to-tr from-white via-blue-200 to-blue-400">
            <img
              className="js-gallery-image-item w-60 h-60 sm:w-96 sm:h-96 relative bottom-10 drop-shadow-md"
              src="https://imgur.com/SW279gJ.png"
              alt="galleryimage"
            />
            <div className="js-gallery-image-text absolute -left-1 bottom-10 sm:-left-4">
              <div className="text-3xl tracking-tight sm:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-blue-600 to-blue-200">
                Solid Flower Ring
              </div>
              <div className="text-sm sm:text-base font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-blue-600 to-blue-200">
                Amazing surface works
              </div>
            </div>
          </div>

          <div className="js-gallery-image shadow-lg m-24 cursor-pointer w-60 h-60 sm:w-96 sm:h-96 mx-auto bg-gradient-to-tr from-white via-purple-200 to-purple-400">
            <img
              className="js-gallery-image-item w-60 h-60 sm:w-96 sm:h-96 relative bottom-10 drop-shadow-md"
              src="https://imgur.com/FPX85Ti.png"
              alt="galleryimage"
            />
            <div className="js-gallery-image-text absolute -left-1 bottom-10 sm:-left-4">
              <div className="text-3xl tracking-tight sm:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-purple-600 to-purple-200">
                Squared Ring
              </div>
              <div className="text-sm sm:text-base font-extrabold text-transparent bg-clip-text bg-gradient-to-bl from-purple-600 to-purple-200">
                Multi channel diamonds setting
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
