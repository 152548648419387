import "./App.css";
import Hero from "./components/Hero.jsx";
import Intro from "./components/Intro.jsx";
import Story from "./components/Story.jsx";
import Gallery from "./components/Gallery.jsx";
import LogoCloud from "./components/LogoCloud.jsx";
import CTA from "./components/CTA.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div attribute="class" className="overflow-x-hidden App">
      <div _style={{ maxHeight: "800px" }}>
        <div className="bg-white">
          <main>
            {/* Hero section */}
            <Hero />

            {/* Intro section */}
            <Intro />

            {/* Story section */}
            <Story />

            {/* Gallery section */}
            <Gallery />

            {/* Logo cloud section */}
            <LogoCloud />

            {/* CTA section */}
            <CTA />
          </main>

          {/* Footer section */}
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
