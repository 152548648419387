import React from "react";
import logo from "../assets/logo.png";
import CrossfadeImage from "react-crossfade-image";
import TextLoop from "react-text-loop";

const Hero = () => {
  const [index, setIndex] = React.useState(0);

  const images = [
    "https://imgur.com/RI4QfHV.png",
    "https://imgur.com/wMshI9H.png",
    "https://imgur.com/EGR1MaW.png",
    "https://imgur.com/lRrhQup.png"
  ];

  const colors = ["gray", "blue", "green", "yellow"];

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      if (index === images.length - 1) {
        setIndex(0);
      } else {
        setIndex((index) => index + 1);
      }
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [index]);
  return (
    <div id="home" className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-28">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div>
          <div className="flex flex-row items-center">
            <img className="w-auto h-16 sm:h-32" src={logo} alt="logo" />
            <div className="align-middle">
              <div className="text-4xl sm:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-yellow-600 to-yellow-200 tracking-tight">
              Jewellery Superiors
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div>
              <a href="#gallery" className="inline-flex space-x-4">
                <span className="rounded bg-yellow-50 px-2.5 py-1 text-xs font-semibold text-yellow-500 tracking-wide uppercase">
                  What's new
                </span>
                <span className="inline-flex items-center text-sm font-medium text-yellow-500 space-x-1">
                  <span>Check out our new special collections</span>
                  <svg
                    className="h-5 w-5"
                    data-todo-x-description="Heroicon name: solid/chevron-right"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
            <div className="mt-6 sm:max-w-xl">
              <h1 className="text-4xl tracking-tight sm:text-5xl">
                <TextLoop interval={3000}>
                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-gray-400 to-gray-700">
                    Fine Sketching
                  </span>
                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-green-600 to-blue-600">
                    Best Designing
                  </span>
                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-green-900">
                    Rapid Prototyping
                  </span>
                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-gray-400">
                    HD Rendering
                  </span>
                </TextLoop>
              </h1>
              <p className="mt-6 text-xl text-gray-500">
                We are a team of designers who has years of experience in
                Jewellery CAD Modelling, Rapid Prototyping, Realistic Rendering,
                and Fine Jewellery Manufacturing
              </p>
            </div>
            <form action="#" className="mt-12 sm:max-w-lg sm:w-full sm:flex">
              <div className="min-w-0 flex-1">
                <label htmlFor="hero_email" className="sr-only">
                  Email address
                </label>
                <input
                  id="hero_email"
                  type="email"
                  className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 outline-none"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md px-5 py-3 bg-gradient-to-r from-yellow-400 to-yellow-600 text-base font-medium text-white shadow-md hover:bg-yellow-600 sm:px-10"
                >
                  Contact Us
                </button>
              </div>
            </form>
            <div className="mt-6">
              <div className="inline-flex items-center divide-x divide-gray-300">
                <div className="flex-shrink-0 flex pr-5">
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    data-todo-x-description="Heroicon name: solid/star"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    data-todo-x-description="Heroicon name: solid/star"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    data-todo-x-description="Heroicon name: solid/star"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    data-todo-x-description="Heroicon name: solid/star"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    data-todo-x-description="Heroicon name: solid/star"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
                <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                  <span className="font-medium text-gray-900">
                    Rated 5 stars
                  </span>{" "}
                  by over{" "}
                  <span className="font-medium text-yellow-500">
                    500 international clients
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="hidden sm:block">
            <div
              className={`bg-${colors[index]}-50 transition absolute inset-y-0 left-1/2 w-scree rounded-l-3xl lg:left-80 lg:right-0 lg:w-full`}
            ></div>
            <svg
              className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0 transition"
              width="404"
              height="392"
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className={`text-${colors[index]}-200`}
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="392"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              ></rect>
            </svg>
          </div>
          <div className="px-16 relative flex flex-row align-middle justify-center sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
            <CrossfadeImage
              src={images[index]}
              duration={1000}
              timingFunction={"ease-in"}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
