import React from "react";

const Footer = () => {
  return (
    <footer className="mt-24 bg-yellow-900 sm:mt-12">
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div className="px-5 py-2">
            <a
              href="#home"
              className="text-base text-white hover:text-yellow-500"
            >
              Home
            </a>
          </div>

          <div className="px-5 py-2">
            <a
              href="#about"
              className="text-base text-white hover:text-yellow-500"
            >
              About
            </a>
          </div>

          <div className="px-5 py-2">
            <a
              href="#story"
              className="text-base text-white hover:text-yellow-500"
            >
              Story
            </a>
          </div>

          <div className="px-5 py-2">
            <a
              href="#gallery"
              className="text-base text-white hover:text-yellow-500"
            >
              Gallery
            </a>
          </div>

          <div className="px-5 py-2">
            <a
              href="#testimonial"
              className="text-base text-white hover:text-yellow-500"
            >
              Testimonial
            </a>
          </div>

          <div className="px-5 py-2">
            <a
              href="#contact-us"
              className="text-base text-white hover:text-yellow-500"
            >
              Contact Us
            </a>
          </div>
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <a
            href="https://www.facebook.com/JSDesigners3D"
            className="text-white hover:text-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Facebook</span>
            <svg
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.instagram.com/js.designers.3d/"
            className="text-white hover:text-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Instagram</span>
            <svg
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>

          <a
            href="/"
            className="text-white hover:text-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Twitter</span>
            <svg
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
            </svg>
          </a>

          <a
            href="https://www.fiverr.com/jsdesigners3d"
            className="text-white hover:text-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Fiverr</span>
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.4 26.5H9.3V13.1H15V26.5H20.9V8.1H9.3V7C9.3 5.8 10.2 5 11.5 5H15V0H10.6C6.3 0 3.4 2.7 3.4 6.6V8.1H0V13.1H3.4V26.5Z"
                fill="currentColor"
              />
            </svg>
          </a>

          <a
            href="https://api.whatsapp.com/send?phone=923452106402&text=💍%20Hey%20Jewellery%20Superior%20Designers%2C%20I%20want%20more%20info"
            className="text-white hover:text-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Whatsapp</span>
            <svg
              width="24"
              height="24"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M20.5019 3.48769C18.2457 1.23993 15.2457 0.00137141 12.0494 0C5.46299 0 0.102956 5.33475 0.100319 11.8919C0.0995518 13.9879 0.649635 16.034 1.6952 17.8375L0 24L6.33436 22.3464C8.0796 23.294 10.0446 23.7933 12.0443 23.7941H12.0494H12.0494C18.6348 23.7941 23.9957 18.4584 23.9984 11.9016C23.9995 8.72364 22.7577 5.73561 20.5019 3.48769V3.48769ZM12.0494 21.7855H12.0456C10.2631 21.7846 8.51525 21.3081 6.99067 20.4075L6.62784 20.1933L2.8689 21.1745L3.87249 17.527L3.63609 17.1531C2.64185 15.5792 2.11698 13.7603 2.11772 11.8927C2.11991 6.44257 6.57497 2.00867 12.0532 2.00867C14.706 2.00976 17.1994 3.03926 19.0746 4.90753C20.9497 6.77579 21.9816 9.25937 21.9809 11.9007C21.9785 17.351 17.5233 21.7855 12.0494 21.7855V21.7855Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M0.548828 23.3163L2.16659 17.4353C1.16853 15.7141 0.643677 13.7614 0.644307 11.7615C0.646924 5.504 5.76217 0.413086 12.0472 0.413086C15.0976 0.414457 17.9606 1.59638 20.1132 3.74152C22.2663 5.88664 23.4513 8.73798 23.4501 11.7704C23.4474 18.028 18.3319 23.1194 12.0476 23.1194C12.0472 23.1194 12.048 23.1194 12.0476 23.1194H12.0426C10.1342 23.1188 8.25913 22.6419 6.59373 21.7382L0.548828 23.3163V23.3163Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.09765 6.8437C8.87659 6.35436 8.64383 6.34456 8.43351 6.336C8.26149 6.32866 8.06455 6.32911 7.86792 6.32911C7.67114 6.32911 7.35137 6.40273 7.08096 6.69676C6.81038 6.99079 6.04785 7.70159 6.04785 9.14724C6.04785 10.593 7.10555 11.99 7.25298 12.1862C7.40057 12.3821 9.29475 15.4436 12.2946 16.6214C14.7877 17.6002 15.2951 17.4055 15.8362 17.3566C16.3774 17.3076 17.5823 16.6459 17.8283 15.9597C18.0743 15.2737 18.0743 14.6857 18.0005 14.5628C17.9267 14.4403 17.7299 14.3668 17.4347 14.2199C17.1396 14.073 15.6886 13.362 15.418 13.2641C15.1475 13.1661 14.9507 13.1171 14.7539 13.4113C14.5571 13.7052 13.9918 14.3668 13.8196 14.5628C13.6475 14.7591 13.4753 14.7836 13.1801 14.6367C12.8849 14.4893 11.9344 14.1793 10.8067 13.1783C9.92937 12.3996 9.33702 11.4377 9.16483 11.1436C8.99265 10.8497 9.14638 10.6905 9.29443 10.544C9.42695 10.4124 9.58961 10.201 9.73719 10.0294C9.88446 9.85784 9.93366 9.7354 10.032 9.53948C10.1304 9.34325 10.0812 9.17169 10.0075 9.02475C9.93366 8.87781 9.36008 7.42471 9.09765 6.8437V6.8437Z"
                  fill="white"
                />
                <path
                  d="M20.4052 3.44962C18.1749 1.22766 15.2094 0.00334413 12.0498 0.00195312C5.53895 0.00195312 0.240447 5.27546 0.23783 11.7574C0.237063 13.8293 0.780829 15.8519 1.81439 17.6347L0.138672 23.7264L6.40032 22.0918C8.12555 23.0285 10.068 23.5221 12.0447 23.5229H12.0497H12.0498C18.5596 23.5229 23.8588 18.2484 23.8616 11.7668C23.8627 8.62546 22.6351 5.67174 20.4052 3.44962V3.44962ZM12.0498 21.5374H12.046C10.284 21.5365 8.55616 21.0655 7.04909 20.1752L6.69042 19.9635L2.97463 20.9334L3.96668 17.3278L3.73301 16.9582C2.75018 15.4023 2.23133 13.6043 2.23209 11.7581C2.23424 6.37061 6.63815 1.9876 12.0535 1.9876C14.6759 1.98868 17.1406 3.00636 18.9944 4.85318C20.848 6.7 21.868 9.15506 21.8672 11.7661C21.8649 17.1538 17.4608 21.5374 12.0498 21.5374Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="11.9995"
                  y1="23.3163"
                  x2="11.9995"
                  y2="0.413124"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#20B038" />
                  <stop offset="1" stopColor="#60D66A" />
                </linearGradient>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <p className="mt-8 text-center text-base text-white">
          © 2021 <a href="https://github.com/GamaTechs" target="_blank" rel="noopener noreferrer" className="no-underline font-bold text-base text-yellow-500">Gama Techs</a> All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
