import React from "react";

const Intro = () => {
  return (
    <div id="about" className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-yellow-500">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1920&amp;q=60&amp;&amp;sat=-100"
            alt=""
          />
          <div
            className="absolute inset-0 bg-yellow-500"
            style={{ mixBlendMode: "multiply" }}
            aria-hidden="true"
          ></div>
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            Welcome
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-white">
            We at Jewellery Superior Designers are a team of talented jewellery
            designers who are dedicated to providing the best and elegant
            jewellery sketches, designs, 3D modelling and rendering services
            worldwide. We provide antique and high-quality designs to build your
            brand and enhance your product range. We give maximum attention to
            details in order to create flawless designs that render our
            jewellery distinctive, classic and elegant.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Learn more
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
          <div className="bg-white rounded-2xl shadow-xl">
            <div className="relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-gray-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <svg
                  className="h-6 w-6 text-white"
                  data-todo-x-description="Heroicon name: outline/phone"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-medium text-gray-900">Sketching</h3>
              <p className="mt-4 text-base text-gray-500">
                We provide best in class sketching over your handwritten
                drawings and digitalize them
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="#sketching"
                className="text-base font-medium text-gray-700 hover:text-gray-600"
              >
                Learn more<span aria-hidden="true"> →</span>
              </a>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-xl">
            <div className="relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <svg
                  className="h-6 w-6 text-white"
                  data-todo-x-description="Heroicon name: outline/support"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-medium text-gray-900">Designing</h3>
              <p className="mt-4 text-base text-gray-500">
                We turn your ideas into reality with our design services,
                providing 100% accurate measurements
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="#designing"
                className="text-base font-medium text-blue-700 hover:text-blue-600"
              >
                Learn more<span aria-hidden="true"> →</span>
              </a>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-xl">
            <div className="relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-green-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <svg
                  className="h-6 w-6 text-white"
                  data-todo-x-description="Heroicon name: outline/newspaper"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-medium text-gray-900">
                Rapid Prototyping
              </h3>
              <p className="mt-4 text-base text-gray-500">
                We convert your digital 3D model into a tangible object by using
                rapid prototyping (local service area, only)
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="#rapid-prototyping"
                className="text-base font-medium text-green-700 hover:text-green-600"
              >
                Learn more<span aria-hidden="true"> →</span>
              </a>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-xl">
            <div className="relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-yellow-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <svg
                  className="h-6 w-6 text-white"
                  data-todo-x-description="Heroicon name: outline/newspaper"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-medium text-gray-900">Rendering</h3>
              <p className="mt-4 text-base text-gray-500">
                We provide you the best in class, high definition 360-degree
                renderings of your 3D models
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="#rendering"
                className="text-base font-medium text-yellow-700 hover:text-yellow-600"
              >
                Learn more<span aria-hidden="true"> →</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
